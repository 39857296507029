.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.img {
  object-fit: fill;
}

.portrait {
  margin: 1rem;
}

.gridbox {
  margin-top: 1rem;
}

.container {
  width: 100%;
}

.image {
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  backface-visibility: hidden;
  object-fit: fill;
}

.disabled {
  filter: grayscale(100%);
  cursor: not-allowed;
}

.container:hover .image {
  opacity: 0.6;
}

.container:hover .middle {
  opacity: 1;
}

.slim-text {
  font-size: "0.966em";
  font-weight: "300";
  white-space: "pre-line";
  text-align: "center";
}

.slimText {
  font-size: 0.966em;
  color: #97969b;
  font-weight: lighter;
  padding-bottom: 5;
  padding-left: 2;
  white-space: nowrap;
}

.textStyle {
  background-color: #1a1d24;
  border: 1.5px solid #3c3f43;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  overflow: auto;
}

.normalText {
  font-size: 0.966em;
  text-align: center;
}

.p-left {
  text-align: left;
}

.side-bar {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  border-right: 3px solid;
  border-radius: 0;
  background-color: #22162b;
  transition: 0.8s ease;
  left: -100%;
}

.textStyle {
  background-color: #1a1d24;
  border: 1.5px solid #3c3f43;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  overflow: auto;
  scrollbar-width: none;
}

.styleCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  margin-bottom: -30px;
}

/* .row:hover {
  background: rgb(255, 255, 255);
} */

.hexagon {
  display: inline-block;
  color: #fff;
  text-shadow: -1px 1px #000000;
  background-color: #e3c33a;
  width: 100px;
  height: 100px;
  /* background: grey; */
  background-size: cover; /* Resize the background image to cover the entire container */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  margin: 1px;
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
}
.hexagon:hover {
  opacity: 0.9;
  cursor: pointer;
  text-shadow: -1px 1px #00aeff;
}

.toggle-menu {
  height: 60px;
  border-top-right-radius: 8rem;
  border-bottom-right-radius: 8rem;
  width: 18px;
  position: absolute;
  z-index: 1;
  background-color: #d4af37;
  border-color: #d4af37;
  border-width: medium;
}

.side-bar {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  border-width: medium;
  background-color: #282c34;
  transition: 0.8s ease;
}

.center {
  position: absolute;
  top: 40%;
  right: 40%;
  font-size: 18px;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.dicovered-text {
  position: absolute;
  top: 35%;
  right: 20%;
  font-size: 12px;
}

button {
  color: whitesmoke;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
}

.rs-list {
  scrollbar-width: thin;
}

.rs-modal {
  z-index: 9999;
}

.blob {
  padding: 0px;
  text-align: left;
  background-color: #15181e;
  white-space: pre-line;
}

ul li {
  margin-left: 1.5em;
}

textarea {
  background-color: #1a1d24;
	border: 1.5px solid #3c3f43;
	border-radius: 5px;
	width: 100%;
	padding: 5px;
	overflow: auto;
	scrollbar-width: none;
}